import React, {useRef} from "react";
import {getSrc} from "gatsby-plugin-image";
import {Swiper, SwiperSlide} from "swiper/react";
import {Autoplay} from "swiper";
import "swiper/css";
import styled from "styled-components";
import SliderControls from "./SliderControls";

const Container = styled.div`
  padding: 20px 0 20px 20px;
  max-width: 100%;

  @media (min-width: 768px) {
    padding: 30px 0 30px 30px;
  }

  @media (min-width: 1024px) {
    display: flex;
    align-items: flex-end;
    width: 512px;
  }

  @media (min-width: 1280px) {
    width: 640px;
  }

  @media (min-width: 1366px) {
    width: 683px;
  }

  @media (min-width: 1440px) {
    width: 720px;
  }

  @media (min-width: 1920px) {
    width: 960px;
  }
`;

const Slide = styled.div`
  width: 100%;
  height: 100%;
  background-color: transparent;
  background-image: ${props => `url(${props.src})`};
  background-size: cover;
  background-position: center center;
`;

const Title = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 10px 0;
  width: 100%;
  font-size: 0.7rem;
  font-weight: 700;
  line-height: 0.7rem;
  text-align: center;
  text-transform: uppercase;
  text-shadow: rgb(0 0 0 / 50%) 0 0 10px;
  background: linear-gradient(to bottom, rgba(26, 26, 26, 0), rgba(26, 26, 26, 0.8));

  @media (min-width: 1366px) {
    padding: 15px 0;
    font-size: 0.8rem;
    line-height: 0.8rem;
  }

  @media (min-width: 1440px) {
    padding: 20px 0;
    font-size: 0.9rem;
    line-height: 0.9rem;
  }

  @media (min-width: 1920px) {
    padding: 20px 0;
    font-size: 1rem;
    line-height: 1rem;
  }
`;

const Slider = ({data, section, setSection}) => {
  let swiperRef = useRef(null);

  const handleSwiper = (swiper) => {
    swiperRef.current = swiper;
  };

  const handleChange = (swiper) => {
    const index = swiper.realIndex;
    if (!(section === null && index === 0)) {
      if (index === 0) {
        setSection(6);
      } else {
        setSection(index - 1);
      }
    }
  };

  const handleSlideClick = (slideId) => {
    swiperRef.current.slideToLoop(slideId + 1);
    setSection(slideId);
  };

  return (
    <Container>
      <Swiper
        style={{width: "100%"}}
        modules={[Autoplay]}
        autoplay={{delay: 10000, disableOnInteraction: false}}
        initialSlide={0}
        slidesPerView={"auto"}
        spaceBetween={10}
        breakpoints={{
          1336: {
            spaceBetween: 15
          },
          1440: {
            spaceBetween: 20
          }
        }}
        loop={true}
        loopedSlides={0}
        grabCursor={true}
        onSlideChange={handleChange}
        onSwiper={handleSwiper}
      >
        {data.allDataJson.nodes[0].sections.map(item => {
          return (
            <SwiperSlide key={item.id} onClick={() => handleSlideClick(item.id)}>
              <Slide src={getSrc(item.thumbnail)}>
                <Title>{item.name}</Title>
              </Slide>
            </SwiperSlide>
          );
        })}
        <SliderControls section={section}/>
      </Swiper>
    </Container>
  );
};

export default Slider;