import React, {useState} from "react";
import {graphql, useStaticQuery} from "gatsby";
import styled from "styled-components";
import Layout from "../components/Layout";
import Info from "../components/Info";
import Slider from "../components/Slider";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 0;
  max-width: 100%;
  height: 100%;

  @media (min-width: 1024px) {
    flex-direction: row;
  }
`;

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    {
      dataJson {
        home {
          description
          background {
            childImageSharp {
              gatsbyImageData(
                breakpoints: [750, 1080, 1366, 1920]
                layout: FULL_WIDTH
                placeholder: BLURRED
              )
            }
          }
        }
      }
      allDataJson {
        nodes {
          sections {
            id
            name
            description
            background {
              childImageSharp {
                gatsbyImageData(
                  breakpoints: [750, 1080, 1366, 1920]
                  layout: FULL_WIDTH
                  placeholder: BLURRED
                )
              }
            }
            thumbnail {
              childImageSharp {
                gatsbyImageData(
                  layout: CONSTRAINED
                  width: 240
                  quality: 80
                )
              }
            }
          }
        }
      }
    }
  `);
  const home = data.dataJson.home;
  const sections = data.allDataJson.nodes[0].sections;
  const [selectedSection, setSection] = useState(null);

  return (
    <Layout pageTitle="Rio Pacifico" pageData={home} section={selectedSection} data={data}>
      <Container>
        {selectedSection === null ? (
          <Info
            isDefault={true}
            title={() => <>THINK.<br/>CREATE.<br/>INNOVATE</>}
            description={home.description}
          />
        ) : (
          sections.map(section => {
            if (section.id === selectedSection) {
              return (
                <Info
                  key={section.id}
                  isDefault={false}
                  title={section.name}
                  description={section.description}
                />
              );
            } else {
              return null;
            }
          })
        )}
        <Slider data={data} section={selectedSection} setSection={setSection}/>
      </Container>
    </Layout>
  );
};

export default IndexPage;