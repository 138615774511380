import React from "react";
import {useSwiper} from "swiper/react";
import {FiChevronLeft, FiChevronRight} from "react-icons/fi";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
  padding-right: 20px;
  margin-top: 15px;

  @media (min-width: 768px) {
    padding-right: 30px;
  }

  @media (min-width: 1440px) {
    padding-right: 40px;
  }
`;

const Buttons = styled.div`
  display: flex;
  margin-right: 15px;

  & > button {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid white;
    border-radius: 50%;
    margin: 0 8px 0 0;
    padding: 5px;
    background-color: rgba(0, 0, 0, 0.2);
    cursor: pointer;

    &:last-child {
      margin-right: 0;
    }

    @media (min-width: 1440px) {
      padding: 8px;
      margin: 0 10px 0 0;
    }
  }

  @media (min-width: 1440px) {
    margin-right: 20px;
  }
`;

const ProgressBar = styled.div`
  margin-right: 15px;
  border-radius: 4px;
  height: 3px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.2);

  @media (min-width: 1440px) {
    margin-right: 20px;
  }
`;

const Progress = styled.div`
  height: 3px;
  width: ${props => props.width};
  background-color: white;
`;

const Number = styled.div`
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1.4rem;

  @media (min-width: 1440px) {
    font-size: 1.6rem;
    line-height: 1.6rem;
  }
`;

const SliderControls = ({section}) => {
  const swiper = useSwiper();

  const getProgress = () => {
    switch (section) {
      case 0:
        return "14.28%";
      case 1:
        return "28.57%";
      case 2:
        return "42.85%";
      case 3:
        return "57.14%";
      case 4:
        return "71.42%";
      case 5:
        return "85.71%";
      case 6:
        return "100%";
      default:
        return "0";
    }
  };

  const getSlideNumber = () => {
    if (section === null) {
      return "00";
    } else {
      return `0${section + 1}`;
    }
  };

  return (
    <Container>
      <Buttons>
        <button onClick={() => swiper.slidePrev()}>
          <FiChevronLeft color="white" size={24}/>
        </button>
        <button onClick={() => swiper.slideNext()}>
          <FiChevronRight color="white" size={24}/>
        </button>
      </Buttons>
      <ProgressBar>
        <Progress width={getProgress()}/>
      </ProgressBar>
      <Number>{getSlideNumber()}</Number>
    </Container>
  );
};

export default SliderControls;